import './ShiftListPageStyles.scss';
import { FC, useContext, useEffect, useState } from "react";
import di from "../../../../di/DependencyInjection";
import { GetUsersWithShiftResponse } from "../../../../domain/repositories/ShiftRepository";
import SearchUsersWithShiftUseCase, { SearchUsersWithShiftUseCaseName } from "../../../../domain/use_cases/shift/SearchUsersWithShiftUseCase";
import { Either } from "fp-ts/lib/Either";
import SearchWithGroupsComponent from "../../../components/searchWithGroups/SearchWithGroupsComponent";
import BusinessUnitEntity from "../../../../domain/entities/BusinessUnitEntity";
import UserEntity from "../../../../domain/entities/UserEntity";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import CalendarWeekFormComponent from "../../../components/forms/calendarWeekForm/CalendarWeekFormComponent";
import { DateOperations } from "../../../utils/DateOperations";
import UserNameWithPhotoComponent from "../../../components/user/userNameWithPhoto/UserNameWithPhotoComponent";
import ShiftAsLineComponent from '../../../components/shiftAsLine/ShiftAsLineComponent';
import DateParse from '../../../utils/DateParse';
import SearchBusinessUnitUseCase, { SearchBusinessUnitUseCaseName } from '../../../../domain/use_cases/businessUnit/SearchBusinessUnitUseCase';
import ButtonComponent from '../../../components/button/ButtonComponent';
import { ButtonType } from '../../../components/button/ButtonComponentProps';
import Icons from '../../../assets/Icons';
import ShiftEntity from '../../../../domain/entities/ShiftEntity';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from '../../../routes/RoutesComponent';
import ModalsContext from '../../../../domain/providers/modal/ModalsContext';
import ModalsContextType from '../../../../domain/providers/modal/ModalsContextType';
import AddShiftComponent from '../../../components/modals/addShift/AddShiftComponent';
import ExceptionEntity from '../../../../domain/entities/ExceptionEntity';
import { right } from 'fp-ts/lib/Either';
import LocalizationContext from '../../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../../domain/providers/localization/LocalizationContextType';
import KeyWordLocalization from '../../../providers/localization/dictionaries/KeyWordLocalization';
import SearchAgentsUseCase, { SearchAgentsUseCaseName } from '../../../../domain/use_cases/user/SearchAgentsUseCase';
import SearchSupervisorUseCase, { SearchSupervisorUseCaseName } from '../../../../domain/use_cases/user/SearchSupervisorUseCase';
import UserContext from '../../../../domain/providers/user/UserContext';
import UserContextType from '../../../../domain/providers/user/UserContextType';
import BulkImportComponent from '../../../components/bulkImport/BulkImportComponent';
import GroupEntity from '../../../../domain/entities/GroupEntity';

//const columnWidth = window.screen.width * 0.09;
const columnheigt = 40;

const ShiftListPage: FC<{}> = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { openModalCustom, closeModalCustom, addToast } = useContext(ModalsContext) as ModalsContextType;
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;

    const { user } = useContext(UserContext) as UserContextType;

    const [isLoading, setIsLoading] = useState(true);

    const [groupId, setGroupId] = useState<string | undefined>(undefined);
    const [page, setPage] = useState<number>(1);
    const [items, setItems] = useState<GetUsersWithShiftResponse[]>([]);
    const itemsPerPage = 100000;
    const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<BusinessUnitEntity[]>([]);
    const [selectedSupervisor, setSelectedSupervisor] = useState<UserEntity[]>([]);
    const [selectedEmployees, setSelectedEmployees] = useState<UserEntity[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<GroupEntity[]>([]);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [currentWeek, setCurrentWeek] = useState<Date[]>(DateOperations.getWeekDays(new Date()));

    const [columnWidth, setColumnWidth] = useState<number>(calculateColumnWidth());
    const [ tokenData, setTokenData ] = useState<string>('');

    const handleScroll = () => {
        const container = document.getElementById('week_content');
        const header = document.getElementById('calendar_header');
        if (container && header) {
            header.style.marginLeft = "-" +  container.scrollLeft + "px";
        }
    };

    const _handleListener = () => {
        const element = document.getElementById("week_content");
        if (element) {
            element.addEventListener('scroll', handleScroll)
        }
    }

    const _searchBusinessUnit = async (word: string): Promise<BusinessUnitEntity[]> => {
        const response = await di.get<SearchBusinessUnitUseCase>(SearchBusinessUnitUseCaseName).call(word, 1, itemsPerPage, [],);
        return response.items;
    }
    const _searchSupervisor = async (word: string, businessUnit: string[]): Promise<UserEntity[]> => {
        const response = await di.get<SearchSupervisorUseCase>(SearchSupervisorUseCaseName).call(word, 1, itemsPerPage, businessUnit);
        return response.items;
    };
    const _searchEmployees = async (word: string, businessUnit: string[], supervisors: string[]): Promise<UserEntity[]> => {
        const filter: { [key: string]: any } = {};
        filter[KeyWordLocalization.UserEntityBUAssociated] = businessUnit;
        const response = await di.get<SearchAgentsUseCase>(SearchAgentsUseCaseName).call(word, 1, itemsPerPage, businessUnit, supervisors);
        return response.items;
    }
    function removeDuplicates(array1: any, array2: any) {
        return array2.filter((item2: any) => 
            !array1.some((item1: any) => item1.user.id === item2.user.id)
        );
    }
    const _getShiftsWithEmployees = async (token: string, businessId?: BusinessUnitEntity[], supervisorId?: UserEntity[], employeesId?: UserEntity[], groups?: GroupEntity[], dates?: Date[]) => {
        const week = dates ?? currentWeek;
        const startDate = week[0];
        const endDate = week[week.length - 1];

        if (startDate === undefined || endDate === undefined) return;
        setIsLoading(true);


        const response = await di.get<SearchUsersWithShiftUseCase>(SearchUsersWithShiftUseCaseName).call(
            businessId ?? selectedBusinessUnit,
            supervisorId ?? selectedSupervisor,
            employeesId ?? selectedEmployees,
            groups ?? selectedGroups,
            startDate, endDate, page, itemsPerPage, token);
        if (response.items.length < itemsPerPage) setHasMore(false);
            
        const currentItems = [...response.items];
        if (token !== '') {
            let removedItems = removeDuplicates(response.items, items);
            currentItems.push(...removedItems);
            //setItems([...currentItems, removedItems[0]]);
            
        }
        // //if some item is already in the list, we update it

        selectedEmployees.forEach(employee => {
            const item = currentItems.find((item: GetUsersWithShiftResponse) => item.user.id == employee.id);
            if (!item) {
                currentItems.push({ user: employee, shifts: [] });
            }
        });

        response.items.sort((a, b) => a.user.name.localeCompare(b.user.name));

        setIsLoading(false);
        setTokenData(response.token || '');
        setItems(currentItems);

        return response;
    }

    const _handleOnChangeSearch = async (businessId: BusinessUnitEntity[], supervisorId: UserEntity[], employeesId: UserEntity[], groups: GroupEntity[]) => {
        setSelectedBusinessUnit(businessId);
        setSelectedSupervisor(supervisorId);
        setSelectedEmployees(employeesId);

        setPage(1);
        setHasMore(true);
        await _getShiftsWithEmployees('', businessId, supervisorId, employeesId, groups);
    }

    const checkIfShiftIsOnWeek = (shift: ShiftEntity) => {
        return currentWeek.find((weekDay: Date) => DateOperations.isSameDate(weekDay, shift.initHour) || DateOperations.isSameDate(weekDay, shift.endHour));
    }

    const _handleAddShift = async () => {
        if (items.some((item) => item.shifts.filter((shift) => checkIfShiftIsOnWeek(shift)).length > 0)) return addToast(i18n(KeyWordLocalization.ShiftListPageSomeEmployeesAlreadyHaveShifts), "error", undefined, true);
        if (items.length == 0 && selectedEmployees.length == 0) return addToast(i18n(KeyWordLocalization.ShiftListPageNoEmployeesSelected), "error", undefined, true);
        let _employeesInFilter = items.map((item) => item.user);
        if (_employeesInFilter.length == 0) _employeesInFilter = selectedEmployees;
        const _handleOnAddedShift = async (shiftsTemp: ShiftEntity[]): Promise<Either<ExceptionEntity, void>> => {
            shiftsTemp.forEach(shift => {
                shift.users = _employeesInFilter;
            });
            closeModalCustom();
            navigate(routes.creating_shift_calendar.relativePath, {
                state: { shiftsCreating: shiftsTemp, weekSelected: currentWeek }
            });
            return right(undefined);
        }
        const dateAsString = `${DateParse.getMonthOfCalendar(currentWeek[0])} ${currentWeek[0].getDate()} - ${DateParse.getMonthOfCalendar(currentWeek[currentWeek.length - 1])} ${currentWeek[currentWeek.length - 1].getDate()}`;
        const disabledPreviousDays = currentWeek.filter(day => !DateOperations.isBeforeToday(day));
        openModalCustom('lg', `${i18n(KeyWordLocalization.ShiftListPageAssignShift)}, ${dateAsString}`, <AddShiftComponent weekSelected={disabledPreviousDays} employees={items.map((item) => item.user)} handleOnAddShift={_handleOnAddedShift} />)
    }

    const _handleOnChangeWeek = (dates: Date[]) => {
        setTokenData('');
        setCurrentWeek(dates);
        _getShiftsWithEmployees('', selectedBusinessUnit, selectedSupervisor, selectedEmployees, selectedGroups, dates);
    }

    const _getXPosition = (date: Date) => {
        const differenceInDays = DateOperations.differenceInDays(date, currentWeek[0]);
        const dayWidth = columnWidth;

        const response = Math.floor(differenceInDays) * dayWidth;
        if (isNaN(response)) return 0;
        return response;
    }

    const _handleGoToEditShift = (user: UserEntity) => {
        navigate(routes.shift_calendar.relativePath, {
            state: { weekSelected: currentWeek, userEditing: user }
        });
    }

    useEffect(() => {
        _getShiftsWithEmployees('');
    }, []);

    useEffect(() => {
        function handleResize() {
            setColumnWidth(calculateColumnWidth());
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function calculateColumnWidth() {
        return window.innerWidth * 0.09;
    }

    const _handleLoadMoreData = () => {
        _getShiftsWithEmployees(tokenData);
    }

    return <div className="shift_list_page">
        <div className="page_content" id='scrollable_container'>
            <div className="container_shit_calendar">
                <div className="row" id='week_header'>
                    <SearchWithGroupsComponent isLoading={setIsLoading} itemsPerPage={itemsPerPage} onSearch={_handleOnChangeSearch} onSearchBusinessUnit={_searchBusinessUnit} onSearchEmployees={_searchEmployees} onSearchSupervisor={_searchSupervisor} aditionalButtons={
                        ((user?.permissions?.shiftTemplate?.create || user?.permissions?.shiftTemplate?.assign ||
                            user?.permissions?.schedule?.create || user?.permissions?.schedule?.assign
                        ) && location.pathname == routes.shift_list.relativePath) ? <div className="col-12 col-md-6 col-lg-2">
                            <BulkImportComponent />
                        </div> : <></>
                    } />
                </div>
                <div className="d-flex justify-content-between flex-wrap">
                    <CalendarWeekFormComponent onChange={_handleOnChangeWeek} selectedWeek={currentWeek} />
                </div>
                <div className="shift_row d-flex" id="calendar_header">
                                <div className="column_nane_shift" style={{ width: columnWidth, height: columnheigt, minWidth: columnWidth }}>
                                </div>
                                <div className="calendar_shift d-flex">
                                    {currentWeek.map((day, index) => <div key={index} className="font-bold column_shift d-flex align-items-center justify-content-end pe-2" style={{ width: columnWidth, minWidth: columnWidth, height: columnheigt }}>
                                        <strong>
                                            {DateParse.getDayOfCalendar(day)} {day.getDate()}
                                        </strong>
                                    </div>)}
                                </div>
                            </div>
                
                {
                    isLoading == false ?
                        <div className={`shift_content hidden`} id='week_content' onLoad={() => _handleListener()}>
                            
                            <div className="none_item"></div>
                            {
                                <div className="row d-flex align-items-stretch">
                                    
                                    {items?.sort((a, b) => a.user.name.localeCompare(b.user.name)).map((item, index) => {
                                        const days = item.shifts.filter(shift => {
                                            return currentWeek.find((weekDay: any) => DateOperations.isSameDate(weekDay, shift.initHour));
                                        });
                                        const reducingWeek = days.map(day => day.initHour);

                                        return (
                                            <div className={`shift_row d-flex ${index % 2 == 0 && 'odd'}`} key={index} >
                                                <div className="column_nane_shift" style={{ width: columnWidth, height: columnheigt, minWidth: columnWidth }} >
                                                    <UserNameWithPhotoComponent user={item.user} photoRadius={columnheigt * .75} />
                                                </div>

                                                <div className="calendar_shift d-flex">
                                                    {currentWeek.map((day, indexDay) => <div key={indexDay} className="column_shift"
                                                        style={{ width: columnWidth, height: columnheigt }}
                                                    >
                                                    </div>)}

                                                    <div className="shift_line_in_page"
                                                        style={{ left: _getXPosition(new Date(reducingWeek[0])) }}
                                                    >
                                                        <ShiftAsLineComponent shifts={item.shifts} width={columnWidth} height={columnheigt - 4} weekToShow={currentWeek} onClick={() => _handleGoToEditShift(item.user)} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            }

                        </div>
                        : <LoadingComponent />
                }
                {
                    tokenData && !isLoading &&
                    <button className='save_filter_button' onClick={() => _handleLoadMoreData()}>load more</button>
                }
                
                

            </div>
            {
                ((user?.permissions?.shiftTemplate?.create || user?.permissions?.shiftTemplate?.assign ||
                    user?.permissions?.schedule?.create || user?.permissions?.schedule?.assign
                ) && location.pathname == routes.shift_list.relativePath) && currentWeek.some((date) => !DateOperations.isBeforeToday(date)) ?

                    <div className="floating_button">
                        <ButtonComponent text={i18n(KeyWordLocalization.ShiftListPageAssignShift)} type={ButtonType.MAIN} onClick={_handleAddShift} icon={<Icons.Plus />} />
                    </div>
                    : <></>
            }

        </div>
    </div>
}

export default ShiftListPage;