import { injectable } from "inversify";
import ShiftRepository, { AsingShiftBulkImportResponse, ChangesOfCalendarWeekParam, GetUsersWithShiftResponse } from "../../../domain/repositories/ShiftRepository";
import { Either, right } from "fp-ts/lib/Either";
import EventScheduleEntity from "../../../domain/entities/EventScheduleEntity";
import ExceptionEntity from "../../../domain/entities/ExceptionEntity";
import SearchResultEntity from "../../../domain/entities/SearchResultEntity";
import ShiftEntity from "../../../domain/entities/ShiftEntity";
import UserEntity from "../../../domain/entities/UserEntity";
import { userTest } from "../user/UserRepositoryFake";
import Testing from "../../../ui/utils/Testing";
import { testEventSchedule } from "../calendar/CalendarRepositoryFake";
import { ShiftRepositoryFake, tempShift } from "./ShiftRepositoryFake";
import SearchShiftTemplateApiImpl from "./api/impl/SearchShiftTemplateApiImpl";
import SearchUsersWithShiftApiImpl from "./api/impl/SearchUsersWithShiftApiImpl";
import AsignShiftApiImpl from "./api/impl/AsignShiftApiImpl";
import GetShiftByIdApiImpl from "./api/impl/GetShiftByIdApiImpl";
import SearchWithUserGroupStatusApiImpl from "./api/impl/SearchWithUserGroupStatusApiImpl";
import CreateMultipleEventsForShiftApiImpl from "./api/impl/CreateMultipleEventsForShiftApiImpl";
import DateParse from "../../../ui/utils/DateParse";
import BusinessUnitEntity from "../../../domain/entities/BusinessUnitEntity";
import GroupEntity from "../../../domain/entities/GroupEntity";
import AsignShiftBulkImportApiImpl from "./api/impl/AsignShiftBulkImportApiImpl";
import UpdateUserWeekApiImpl from "./api/impl/UpdateUserWeekApiImpl";

@injectable()
export class ShiftRepositoryDev implements ShiftRepository {
    updateUserWeek = UpdateUserWeekApiImpl;
    asignShiftBulkImport = AsignShiftBulkImportApiImpl;
    createMultipleEventsForShift = (events: EventScheduleEntity[], employees: UserEntity[], shiftGroupId: string): Promise<Either<ExceptionEntity, void>> => CreateMultipleEventsForShiftApiImpl(events, employees, shiftGroupId);
    searchShiftTemplate = (businessUnitId: string, days: Date[], word: string, page: number, itemsPerPage: number): Promise<SearchResultEntity<ShiftEntity>> => SearchShiftTemplateApiImpl(businessUnitId, days, word, page, itemsPerPage);
    asignShfit = AsignShiftApiImpl;
    getShiftById = (shiftId: string): Promise<Either<ExceptionEntity, ShiftEntity>> => GetShiftByIdApiImpl(shiftId);
    searchUsersWithShift = (businessUnitId: BusinessUnitEntity[], supervisorId: UserEntity[], employeesIds: UserEntity[], groupsIds: GroupEntity[], startDate: Date, enddate: Date, page: number, itemsPerPage: number, nextToken: string): Promise<SearchResultEntity<GetUsersWithShiftResponse>> => SearchUsersWithShiftApiImpl(
        businessUnitId.map((bu) => bu.id),
        supervisorId.map((bu) => bu.id),
        employeesIds.map((bu) => bu.id),
        groupsIds.map((bu) => bu.id),
        startDate, enddate, page, itemsPerPage, nextToken);
    searchWithUserGroupStatus = SearchWithUserGroupStatusApiImpl;
    getShiftCalendar = async (shiftId: string, startDate: Date, endDate: Date): Promise<Either<ExceptionEntity, ShiftEntity[]>> => {
        const days = DateParse.fillArrayOfDates(startDate, endDate);
        await Testing.sleeper(1000);
        return right(days.map((day: Date) => {
            const dayEntity: ShiftEntity = {
                initHour: day,
                endHour: day,
                id: "1",
                shiftGroup: "1",
                users: [userTest],
                events: [testEventSchedule]
            }
            return dayEntity;
        }));
    }
    createShift = async (shift: ShiftEntity): Promise<Either<ExceptionEntity, ShiftEntity>> => {
        await Testing.sleeper(1000);
        return Promise.resolve(right(shift));
    }
    createEventForShift = async (event: EventScheduleEntity, shiftId: string): Promise<Either<ExceptionEntity, EventScheduleEntity>> => {
        await Testing.sleeper(1000);
        return Promise.resolve(right(event));
    }
    updateEventForShift = async (event: EventScheduleEntity, shiftId: string): Promise<Either<ExceptionEntity, EventScheduleEntity>> => {
        await Testing.sleeper(1000);
        return Promise.resolve(right(event));
    }
    searchShifts = async (businessUnitId: string[], supervisorId: string[], employeesIds: string[], groupId: string | undefined, date: Date[], word: string, page: number, itemsPerPage: number): Promise<SearchResultEntity<ShiftEntity>> => {
        await Testing.sleeper(1000);
        return {
            items: Array(itemsPerPage).fill(tempShift),
            pages: page,
            total: itemsPerPage * 3
        }
    }
    searchUsersOfShift = async (shiftId: string, word: string, page: number, itemsPerPage: number): Promise<SearchResultEntity<UserEntity>> => {
        await Testing.sleeper(1000);
        return {
            items: Array(itemsPerPage).fill(userTest),
            pages: page,
            total: itemsPerPage * 3
        }
    }
}