import StaffPerShiftDayEntity from "../../../domain/entities/StaffPerShiftDayEntity";
import DateParse from "../../../ui/utils/DateParse";

const fromJSON = (json: any): StaffPerShiftDayEntity => {
    const staffPerShiftDayEntity: StaffPerShiftDayEntity = {
        date: new Date(json.shift_date),
        absent: {
            count: json.Absent || 0,
            users: json.Absent_employees ? json.Absent_employees.split(',') : [],
            color: '#233746',
        },
        active: {
            count: json.Active || 0,
            users: json.Active_employees ? json.Active_employees.split(',') : [],
            color: '#5ab529',
        },
        training: {
            count: json.Training || 0,
            users: json.Training_employees ? json.Training_employees.split(',') : [],
            color: '#e6c860',
        },
        bereavement: {
            count: json.Bereavement || 0,
            users: json.Bereavement_employees ? json.Bereavement_employees.split(',') : [],
            color: '#ff5733',
        },
        break: {
            count: json.Break || 0,
            users: json.Break_employees ? json.Break_employees.split(',') : [],
            color: '#8b0000',
        },
        client_System_Issues: {
            count: json.Client_System_Issues || 0,
            users: json.Client_System_Issues_employees ? json.Client_System_Issues_employees.split(',') : [],
            color: '#1e90ff',
        },
        itel_System_Issues: {
            count: json.Itel_System_Issues || 0,
            users: json.Itel_System_Issues_employees ? json.Itel_System_Issues_employees.split(',') : [],
            color: '#ff4500',
        },
        lieu_Day: {
            count: json.Lieu_Day || 0,
            users: json.Lieu_Day_employees ? json.Lieu_Day_employees.split(',') : [],
            color: '#808080',
        },
        lunch: {
            count: json.Lunch || 0,
            users: json.Lunch_employees ? json.Lunch_employees.split(',') : [],
            color: '#ffa500',
        },
        medical_Leave: {
            count: json.Medical_Leave || 0,
            users: json.Medical_Leave_employees ? json.Medical_Leave_employees.split(',') : [],
            color: '#ff1493',
        },
        meeting: {
            count: json.Meeting || 0,
            users: json.Meeting_employees ? json.Meeting_employees.split(',') : [],
            color: '#9370db',
        },
        off_Duty: {
            count: json.Off_Duty || 0,
            users: json.Off_Duty_employees ? json.Off_Duty_employees.split(',') : [],
            color: '#2f4f4f',
        },
        other: {
            count: json.Other || 0,
            users: json.Other_employees ? json.Other_employees.split(',') : [],
            color: '#d2691e',
        },
        overtime: {
            count: json.Overtime || 0,
            users: json.Overtime_employees ? json.Overtime_employees.split(',') : [],
            color: '#ffd700',
        },
        paid_Time_Off: {
            count: json.Paid_Time_Off || 0,
            users: json.Paid_Time_Off_employees ? json.Paid_Time_Off_employees.split(',') : [],
            color: '#32cd32',
        },
        pause: {
            count: json.Pause || 0,
            users: json.Pause_employees ? json.Pause_employees.split(',') : [],
            color: '#20b2aa',
        },
        personal_Time: {
            count: json.Personal_Time || 0,
            users: json.Personal_Time_employees ? json.Personal_Time_employees.split(',') : [],
            color: '#4169e1',
        },
        public_Holiday: {
            count: json.Public_Holiday || 0,
            users: json.Public_Holiday_employees ? json.Public_Holiday_employees.split(',') : [],
            color: '#8a2be2',
        },
        site_Closed: {
            count: json.Site_Closed || 0,
            users: json.Site_Closed_employees ? json.Site_Closed_employees.split(',') : [],
            color: '#708090',
        },
        support: {
            count: json.Support || 0,
            users: json.Support_employees ? json.Support_employees.split(',') : [],
            color: '#4682b4',
        },
        suspension: {
            count: json.Suspension || 0,
            users: json.Suspension_employees ? json.Suspension_employees.split(',') : [],
            color: '#ff6347',
        },
        vacation: {
            count: json.Vacation || 0,
            users: json.Vacation_employees ? json.Vacation_employees.split(',') : [],
            color: '#00ced1',
        },
        voluntary_Time_Off: {
            count: json.Voluntary_Time_Off || 0,
            users: json.Voluntary_Time_Off_employees ? json.Voluntary_Time_Off_employees.split(',') : [],
            color: '#ff69b4',
        },
        workTime: {
            count: json.WorkTime || 0,
            users: json.WorkTime_employees ? json.WorkTime_employees.split(',') : [],
            color: '#228b22',
        },
    };

    return staffPerShiftDayEntity;
};

export default {
    fromJSON,
};
