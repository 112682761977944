import { left } from "fp-ts/lib/EitherT";
import SearchResultEntity from "../../../../../domain/entities/SearchResultEntity";
import ShiftEntity from "../../../../../domain/entities/ShiftEntity";
import callAPI from "../../../fetchCallImplementation";
import Constants, { ConstantsName } from "../../../../../constants/Constants";
import di from "../../../../../di/DependencyInjection";
import StaffPerShiftEntity from "../../../../../domain/entities/StaffPerShiftEntity";
import Host2Api, { Host2ApiName } from "../../../../settings/Host2Api";
import StaffPerShiftHostDto from "../../../../dto/impl/StaffPerShiftHostDto";
import UserEntity from "../../../../../domain/entities/UserEntity";
import BusinessUnitEntity from "../../../../../domain/entities/BusinessUnitEntity";
import GroupEntity from "../../../../../domain/entities/GroupEntity";
import DateParse from "../../../../../ui/utils/DateParse";

//TODO implement
const SearchWithUserGroupStatusApiImpl = async (businessUnit: BusinessUnitEntity[], supervisors: UserEntity[], employees: UserEntity[], groups: GroupEntity[], days: Date[], page: number, itemsPerPage: number): Promise<SearchResultEntity<StaffPerShiftEntity>> => {

    let params = "";

    if (groups.length > 0) {
        params = groups.map((group) => 'groups=' + parseInt(group.id)).join('&');
    } else if (employees.length > 0) {
        params = employees.map((employee) => 'userid=' + parseInt(employee.id)).join('&');
    } else if (supervisors.length > 0) {
        params = supervisors.map((supervisor) => 'supervisorid=' + parseInt(supervisor.id)).join('&');
    } else if (businessUnit.length > 0) {
        params = businessUnit.map((businessUnit) => 'businessunits=' + businessUnit.id).join('&');
    }

    const startDate = days[0];
    const endDate = days[days.length - 1];

    let relativeUrl = `/staffpershift?${params}&starting_date=${DateParse.formatDateForApi(startDate)}&ending_date=${DateParse.formatDateForApi(endDate)}`;

    let anotherURL =  `/staffpershiftmetrics?starting_date=${DateParse.formatDateForApi(startDate)}&ending_date=${DateParse.formatDateForApi(endDate)}`;

    const hasAgentId = relativeUrl.replace('&&', '&');
    
    try {
        const response = await di.get<Host2Api>(Host2ApiName).get(hasAgentId);
        
        const hasAgentId2 = anotherURL.replace('&&', '&');
        const response2 = await di.get<Host2Api>(Host2ApiName).get(hasAgentId2);

        // Sample data
        const dataset1 = response;
        const dataset2 = response2;

        // Step 1: Create a lookup map from the second dataset
        const lookup = dataset2.data.reduce((acc: any, item: any) => {
            acc[item.shift_id] = item;
            return acc;
        }, {});

        // Step 2: Merge datasets based on shift_id
        const mergedData = dataset1.data.map((item: any) => {
            const matchedItem = lookup[item.shift_id] || {}; // Find matching item or default to {}
            return {
                ...item, // Include all properties from the first dataset
                ...matchedItem // Merge properties from the matching item in the second dataset
            };
        });

        const objectMap: { [key: string]: any } = {};

        mergedData.forEach((item: any) => {
            if (!objectMap[item.name]) {
                objectMap[item.name] = {
                    name: item.name,
                    days: [item],
                }
            } else {
                objectMap[item.name].days.push(item);
            }
        });
        
        const daysCasted = Object.values(objectMap).map((item) => StaffPerShiftHostDto.fromJSON(item));

        return {
            items: daysCasted,
            pages: 100,
            total: daysCasted.length
        }

    } catch (error) {
        return {
            items: [],
            pages: 0,
            total: 0,
        }
    }



}

export default SearchWithUserGroupStatusApiImpl;