import './ShiftAsLineComponentStyles.scss';
import { FC, useContext, useState } from "react";
import ShiftAsLineComponentProps from "./ShiftAsLineComponentProps";
import { DateOperations } from "../../utils/DateOperations";
import DateParse from '../../utils/DateParse';
import LocalizationContext from '../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../domain/providers/localization/LocalizationContextType';
import KeyWordLocalization from '../../providers/localization/dictionaries/KeyWordLocalization';
import ShiftEntity from '../../../domain/entities/ShiftEntity';
import Tooltip  from 'react-bootstrap/Tooltip';
import { OverlayTrigger } from 'react-bootstrap';

const ShiftAsLineComponent: FC<ShiftAsLineComponentProps> = ({ shifts, onClick, width, height, weekToShow }) => {

    let weekToRender = weekToShow.map(week => {
        let color = shifts.filter(shift => new Date(shift.initHour).setHours(0,0,0,0) === new Date(week).setHours(0,0,0,0));
        return {
            day: week,
            color: color.length > 0 ? color[0].color : '',
            activity_tag: color.length > 0 ? color[0].activity_tag : ''
        }
    });
    const sortedDates = shifts.sort((a: any, b: any) => a.initHour.getTime() - b.initHour.getTime())[0];
    
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const calculateWidth = (): number => {
        const removedWeeksUnsort = removeWeeksNotToShow();
        const weekReduced = removedWeeksUnsort.sort((a, b) => a.getTime() - b.getTime());

        if (weekReduced.length <= 0 || weekReduced[0] == null || weekReduced[weekReduced.length - 1] == null) return 0;
        const daysDifference = Math.abs(DateOperations.differenceInDays(weekReduced[0], weekReduced[weekReduced.length - 1]) - 1);

        return Math.abs(Math.ceil(daysDifference)) * width;
    }

    const removeWeeksNotToShow = (): Date[] => {
        const days = shifts.filter(day => {
            return weekToRender.find(weekDay => DateOperations.isSameDate(weekDay.day, day.initHour));
        });
        return days.map(day => day.initHour);
    }

    const _existingDate = (date: Date): string => {

        const findDate = shifts.find(day => DateOperations.isSameDate(day.initHour, date)) as ShiftEntity;
        if (findDate) return `${DateParse.getHourAmPm(findDate.initHour)} - ${DateParse.getHourAmPm(findDate.endHour)}`
        return '';
    }

    const isBetween = (date: Date): boolean => {
        const removedWeeksUnsort = removeWeeksNotToShow();
        const removedWeeks = removedWeeksUnsort.sort((a, b) => a.getTime() - b.getTime());
        const start = new Date(removedWeeks[0]);
        const end = new Date(removedWeeks[removedWeeks.length - 1]);
        if (start == null || end == null) return false;
        end.setDate(end.getDate() + 1);
        return date.setHours(0, 0, 0, 0) >= start.setHours(0, 0, 0, 0) && date.setHours(0, 0, 0, 0) <= end.setHours(0, 0, 0, 0);
    }

    return <div className="shift_as_line_component" style={{ width: calculateWidth(), height: height}} >
        <div className={`shift_hour_container ${onClick && 'hover'}`} onClick={() => onClick?.(shifts)}>

            <div className='p-2 shift_title shift_filled' style={{ width: width / 2, height: height, fontSize: "14px", backgroundColor: sortedDates?.color }}>
                {calculateWidth() > 0 && <strong>{i18n(KeyWordLocalization.ShiftEntity)}</strong>}
            </div>

            {weekToRender.map((day, index) => 
                isBetween(day.day) ? (
                    <OverlayTrigger
                    key={index}
                    placement="top" // You can change the placement (top, bottom, left, right)
                    overlay={
                        <Tooltip id={`tooltip-${index}`}>
                        {day.activity_tag || ''}
                        </Tooltip>
                    }
                    >
                    <div 
                        className={`shift_hour ${_existingDate(day.day) !== '' ? 'shift_filled' : 'shift_not_filled'}`} 
                        style={{ 
                        height: height, 
                        width: width / (DateOperations.isSameDate(day.day, removeWeeksNotToShow()[0]) ? 2 : 1), 
                        fontSize: "12px", 
                        backgroundColor: day?.color 
                        }}
                    >
                        <div className="shift_hour_content">
                        {_existingDate(day.day)}
                        </div>
                    </div>
                    </OverlayTrigger>
                ) : ''
                )}
        </div>
    </div>
}

export default ShiftAsLineComponent;