import { injectable } from "inversify";
import ShiftRepository, { ChangesOfCalendarWeekParam, GetUsersWithShiftResponse } from "../../../domain/repositories/ShiftRepository";
import { Either } from "fp-ts/lib/Either";
import EventScheduleEntity from "../../../domain/entities/EventScheduleEntity";
import ExceptionEntity from "../../../domain/entities/ExceptionEntity";
import SearchResultEntity from "../../../domain/entities/SearchResultEntity";
import ShiftEntity from "../../../domain/entities/ShiftEntity";
import UserEntity from "../../../domain/entities/UserEntity";
import CreateShiftApiImpl from "./api/impl/CreateShiftApiImpl";
import CreateEventForShiftApiImpl from "./api/impl/CreateEventForShiftApiImpl";
import SearchUsersOfShiftApiImpl from "./api/impl/SearchUsersOfShiftApiImpl";
import SearchUsersWithShiftApiImpl from "./api/impl/SearchUsersWithShiftApiImpl";
import SearchWithUserGroupStatusApiImpl from "./api/impl/SearchWithUserGroupStatusApiImpl";
import GetShiftCalendarApiImpl from "./api/impl/GetShiftCalendarApiImpl";
import GetShiftByIdApiImpl from "./api/impl/GetShiftByIdApiImpl";
import UpdateEventForShiftApiImpl from "./api/impl/UpdateEventForShiftApiImpl";
import AsignShiftApiImpl from "./api/impl/AsignShiftApiImpl";
import SearchShiftApiImpl from "./api/impl/SearchShiftApiImpl";
import SearchShiftTemplateApiImpl from "./api/impl/SearchShiftTemplateApiImpl";
import CreateMultipleEventsForShiftApiImpl from "./api/impl/CreateMultipleEventsForShiftApiImpl";
import BusinessUnitEntity from "../../../domain/entities/BusinessUnitEntity";
import GroupEntity from "../../../domain/entities/GroupEntity";
import AsignShiftBulkImportApiImpl from "./api/impl/AsignShiftBulkImportApiImpl";
import UpdateUserWeekApiImpl from "./api/impl/UpdateUserWeekApiImpl";

@injectable()
export class ShiftRepositoryImpl implements ShiftRepository {
    updateUserWeek = UpdateUserWeekApiImpl;
    createMultipleEventsForShift = (events: EventScheduleEntity[], employees: UserEntity[], shiftGroupId: string): Promise<Either<ExceptionEntity, void>> => CreateMultipleEventsForShiftApiImpl(events, employees, shiftGroupId);
    asignShfit = AsignShiftApiImpl;
    getShiftCalendar = (shiftId: string, startDate: Date, endDate: Date): Promise<Either<ExceptionEntity, ShiftEntity[]>> => GetShiftCalendarApiImpl(shiftId, startDate, endDate);
    createShift = (shift: ShiftEntity): Promise<Either<ExceptionEntity, ShiftEntity>> => CreateShiftApiImpl(shift);
    createEventForShift = (event: EventScheduleEntity, shiftId: string): Promise<Either<ExceptionEntity, EventScheduleEntity>> => CreateEventForShiftApiImpl(event, shiftId);
    updateEventForShift = (event: EventScheduleEntity, shiftId: string): Promise<Either<ExceptionEntity, EventScheduleEntity>> => UpdateEventForShiftApiImpl(event, shiftId);
    getShiftById = (shiftId: string): Promise<Either<ExceptionEntity, ShiftEntity>> => GetShiftByIdApiImpl(shiftId);
    searchShifts = (businessUnitId: string[], supervisorId: string[], employeesIds: string[], groupId: string | undefined, date: Date[], word: string, page: number, itemsPerPage: number): Promise<SearchResultEntity<ShiftEntity>> => SearchShiftApiImpl(businessUnitId, supervisorId, employeesIds, groupId, date, word, page, itemsPerPage)
    searchShiftTemplate = (businessUnitId: string, days: Date[], word: string, page: number, itemsPerPage: number): Promise<SearchResultEntity<ShiftEntity>> => SearchShiftTemplateApiImpl(businessUnitId, days, word, page, itemsPerPage);
    searchUsersWithShift = (businessUnitId: BusinessUnitEntity[], supervisorId: UserEntity[], employeesIds: UserEntity[], groupsIds: GroupEntity[], startDate: Date, enddate: Date, page: number, itemsPerPage: number, nextToken: string): Promise<SearchResultEntity<GetUsersWithShiftResponse>> => SearchUsersWithShiftApiImpl(
        businessUnitId.map((bu) => bu.id),
        supervisorId.map((bu) => bu.id),
        employeesIds.map((bu) => bu.id),
        groupsIds.map((bu) => bu.id),
        startDate, enddate, page, itemsPerPage, nextToken);
    searchUsersOfShift = (shiftId: string, word: string, page: number, itemsPerPage: number): Promise<SearchResultEntity<UserEntity>> => SearchUsersOfShiftApiImpl(shiftId, word, page, itemsPerPage);
    searchWithUserGroupStatus = SearchWithUserGroupStatusApiImpl;
    asignShiftBulkImport = AsignShiftBulkImportApiImpl;
}