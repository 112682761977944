import './StaffPerShiftListPageStyless.scss';
import { FC, useContext, useEffect, useRef, useState } from "react";
import di from "../../../../di/DependencyInjection";
import SearchWithGroupsComponent from "../../../components/searchWithGroups/SearchWithGroupsComponent";
import BusinessUnitEntity from "../../../../domain/entities/BusinessUnitEntity";
import UserEntity from "../../../../domain/entities/UserEntity";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import CalendarWeekFormComponent from "../../../components/forms/calendarWeekForm/CalendarWeekFormComponent";
import { DateOperations } from "../../../utils/DateOperations";
import DateParse from '../../../utils/DateParse';
import SearchBusinessUnitUseCase, { SearchBusinessUnitUseCaseName } from '../../../../domain/use_cases/businessUnit/SearchBusinessUnitUseCase';
import SearchShiftsWithUsersGroupByStatusUseCase, { SearchShiftsWithUsersGroupByStatusUseCaseName } from '../../../../domain/use_cases/shift/SearchShiftsWithUsersGroupByStatusUseCase';
import SearchSupervisorUseCase, { SearchSupervisorUseCaseName } from '../../../../domain/use_cases/user/SearchSupervisorUseCase';
import SearchAgentsUseCase, { SearchAgentsUseCaseName } from '../../../../domain/use_cases/user/SearchAgentsUseCase';
import StaffPerShiftEntity from '../../../../domain/entities/StaffPerShiftEntity';
import SearchResultEntity from '../../../../domain/entities/SearchResultEntity';
import LocalizationContext from '../../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../../domain/providers/localization/LocalizationContextType';
import KeyWordLocalization from '../../../providers/localization/dictionaries/KeyWordLocalization';
import NotResultsComponent from '../../../components/notResults/NotResultsComponent';
import Icons from '../../../assets/Icons';
import GraphApi from '../../../../data/settings/GraphApi';
import { isRight } from 'fp-ts/lib/Either';

const ShiftListPage: FC<{}> = () => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const [groupId, setGroupId] = useState<string | undefined>(undefined);
    const [page, setPage] = useState<number>(1);
    const [items, setItems] = useState<StaffPerShiftEntity[]>([]);
    const itemsPerPage = 100000;
    const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<BusinessUnitEntity[]>([]);
    const [selectedSupervisor, setSelectedSupervisor] = useState<UserEntity[]>([]);
    const [selectedEmployees, setSelectedEmployees] = useState<UserEntity[]>([]);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [currentWeek, setCurrentWeek] = useState<Date[]>(DateOperations.getWeekDays(new Date())); //undefined: loading, null: not found

    const [isLoading, seIsLoading] = useState(true);

    const [ modalEvents, setModalEvents ] = useState(false);
    const [ eventsSelected, setEventsSelected ] = useState<any>();
    const [ modalUsers, setModalUsers ] = useState(false);
    const  [ usersSelected, setUsersSelected ] = useState<any>();
    const [ typesList, setTypesList ] = useState<any>();

    const modalRef = useRef<HTMLDivElement | null>(null);

    const handleOutsideClick = (event: MouseEvent) => {
        if (
            modalRef.current &&
            !modalRef.current.contains(event.target as Node)
        ) {
            setModalEvents(false);
            setModalUsers(false);
        }
    };

    useEffect(() => {
        if (modalEvents || modalUsers) {
            document.addEventListener("mousedown", handleOutsideClick);
        } else {
            document.removeEventListener("mousedown", handleOutsideClick);
        }

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [modalEvents, modalUsers]);

    const handleScroll = () => {
        const container = document.getElementById('week_content_staff');
        const header = document.getElementById('calendar_header_staff');
        if (container && header) {
            if (container.scrollLeft === 0) {
                header.style.marginLeft = "" +  (0) + "px";
            } else {
                // Sync the scroll position of the header with the container
                header.style.marginLeft = "-" +  (container.scrollLeft - 0) + "px";
            }
        }
    };

    const _handleListener = () => {
        const element = document.getElementById("week_content_staff");
        if (element) {
            element.addEventListener('scroll', handleScroll)
        }
    }

    const _searchBusinessUnit = async (word: string): Promise<BusinessUnitEntity[]> => {
        const response = await di.get<SearchBusinessUnitUseCase>(SearchBusinessUnitUseCaseName).call(word, 1, itemsPerPage, []);
        return response.items;
    }
    const _searchSupervisor = async (word: string, businessUnit: string[]): Promise<UserEntity[]> => {
        const response = await di.get<SearchSupervisorUseCase>(SearchSupervisorUseCaseName).call(word, 1, itemsPerPage, businessUnit);
        return response.items;
    }
    const _searchEmployees = async (word: string, businessUnit: string[], supervisors: string[]): Promise<UserEntity[]> => {

        const response = await di.get<SearchAgentsUseCase>(SearchAgentsUseCaseName).call(word, 1, itemsPerPage, businessUnit, supervisors);
        return response.items;
    }
    const _getShiftsGrouped = async () => {
        seIsLoading(true);
        const response: SearchResultEntity<StaffPerShiftEntity> = await di.get<SearchShiftsWithUsersGroupByStatusUseCase>(SearchShiftsWithUsersGroupByStatusUseCaseName).call(selectedBusinessUnit, selectedSupervisor, selectedEmployees, [], currentWeek, page, itemsPerPage);
        const responseTypes = await callingApi();
        if (isRight(responseTypes)) {
            setTypesList(responseTypes.right.data.listTag_types);
        }
        
        if (response?.items?.length < itemsPerPage) setHasMore(false);

        //order and filter
        const filtred = response.items.filter(item => item.days.length > 0);
        const sorted = filtred.sort((a, b) => a.name.localeCompare(b.name));
        seIsLoading(false);

        setItems(sorted);
    }

    const _handleOnChangeSearch = async (businessId: BusinessUnitEntity[], supervisorId: UserEntity[], employeesId: UserEntity[]) => {
        setSelectedBusinessUnit(businessId);
        setSelectedSupervisor(supervisorId);
        setSelectedEmployees(employeesId);
        setPage(1);
        setHasMore(true);
        await _getShiftsGrouped();
    }

    const _handleOnChangeWeek = (dates: Date[]) => {
        setCurrentWeek(dates);
        _getShiftsGrouped();
    }
    const callingApi = async () => {
        let response = await GraphApi.query('listTag_types', {}, ['tag_type_id', 'activity_tag', 'color', 'description']);
        return response;
    }

    
    useEffect(() => {
        _getShiftsGrouped();
    }, []);

    useEffect(() => {
        _handleListener();
    }, [isLoading, items.length])
    // Array to store Date objects

    const renderStaffData = (data: any) => {
        // Transform the object into an array of fields
        const fields: any[] = Object.entries(data).flatMap(([key, value]) => {
            
            // Check if the value is an object and not null, and if it contains 'count' and 'color' properties
            if (value && typeof value === 'object' && 'count' in value && 'color' in value && 'users' in value) {
                return {
                    name: key,
                    count: value.count,
                    color: value.color,
                    users: value.users
                };
            }
            // Return an empty array for other cases
            return [];
        });
    
        // You can now use `fields` for rendering or further processing
        return fields;
    };
    
    

    const _handleOpenEventModal = (events: any) => {
        setModalEvents(!modalEvents);
        let dataEventArray = renderStaffData(events).filter(item => item.name !== 'Active' && item.name !== 'Absent' && item.name !== 'Date');
        setEventsSelected(dataEventArray);
    } 

    const _handleOpenUsers = (users: any) => {
        setModalUsers(!modalUsers);
        setUsersSelected(users.users);
    }

    const formatName = (name: string) => {
        return name
            .split("_") // Split the string by underscores
            .join(" ") // Join the array into a single string with spaces
            .replace(/(^\w|\s\w)/g, (match) => match.toUpperCase()); // Capitalize the first letter of each word
    }

    return <div className="staff_per_shift_page">
        <div className="page_content" id='scrollable_container'>
            <div className="container">
                <div className="row">
                    <SearchWithGroupsComponent isLoading={seIsLoading} itemsPerPage={itemsPerPage} setItems={setItems} onSearch={_handleOnChangeSearch} onSearchBusinessUnit={_searchBusinessUnit} onSearchEmployees={_searchEmployees} onSearchSupervisor={_searchSupervisor} from={"staffPerShift"} />
                </div>
                <div className="d-flex justify-content-between flex-wrap">
                    <CalendarWeekFormComponent onChange={_handleOnChangeWeek} selectedWeek={currentWeek} />
                </div>
                <div id="calendar_header_staff">
                    <table className='table'>
                        <thead>
                            <tr>
                                <th style={{maxWidth: '300px', minWidth: '300px', left: '-18.5px'}}></th>
                                {currentWeek.map((day, index) => <th style={{maxWidth: '375px', minWidth: '375px'}} key={index} className="text-center">
                                    {DateParse.getDayOfCalendar(day)} {day.getDate()}
                                </th>)}
                            </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>
                <div className="row mt-4">
                    <div className="none_item"></div>
                    {!isLoading && items.length == 0 && <NotResultsComponent />}
                    {
                        !isLoading && items.length > 0 ?

                            <div className="table-responsive" id='week_content_staff'>
                                <table className='table'>
                                    
                                    <tbody>
                                        {items?.map((bu, index) => <tr key={index}>
                                            <td style={{maxWidth: '300px', minWidth: '300px', left: '-20px'}}>{bu.name}</td>
                                            {currentWeek.map((day, index) => {
                                                const shift = bu.days.find(dayShift => DateOperations.isSameDate(dayShift.date, day));
                                                
                                                if (!shift) return <td style={{maxWidth: '375px', minWidth: '375px'}} key={index} className="text-end"></td>
                                                return <td style={{maxWidth: '375px', minWidth: '375px'}} key={index} className="text-end">
                                                    <div className="d-flex justify-content-end shift_cell flex-wrap">
                                                        <div className="d-flex align-items-center shift_box">
                                                            {!DateOperations.isAfterToday(day) &&
                                                                <div className="me-2 d-flex align-items-center">
                                                                    <div className="shift_circle me-2" style={{ backgroundColor: shift.absent?.color }}></div> {shift.absent?.count} {i18n(KeyWordLocalization.StaffPerShiftListPageAbsent)}
                                                                </div>
                                                            }
                                                            <div className="me-2 d-flex align-items-center">
                                                                <div className="shift_circle me-2" style={{ backgroundColor: shift.active?.color }}></div> {shift.active?.count} {i18n(KeyWordLocalization.StaffPerShiftListPageActive)}
                                                            </div>
                                                            <div className='viewall' onClick={() => {_handleOpenEventModal(shift)}}>view all</div>
                                                            
                                                        </div>
                                                    </div>
                                                </td>
                                            })}
                                        </tr>)}
                                    </tbody>
                                </table>

                            </div>
                            : <LoadingComponent />
                    }

                </div>
            </div>
        </div>
        {
        modalEvents && eventsSelected &&
            <div className='back_modal_events'>
                <div className='modal_inner'
                    ref={modalRef} 
                >
                    <div style={{display: 'flex', padding: '2px', minHeight: '30px'}}>
                        <div onClick={() => {
                            setModalEvents(true);
                            setModalUsers(false);
                        }} style={{width: '50%', display: 'flex', justifyContent: 'flex-start'}}>
                            {
                                modalUsers ?
                                <div style={{cursor: 'pointer',  alignItems: 'center',}}><Icons.ArrowBack /></div> : <></>
                            }
                            
                        </div>
                        <div onClick={() => {
                                setModalEvents(false);
                                setModalUsers(false);
                            }} style={{width: '50%', display: 'flex', justifyContent: 'flex-end', }}>
                            <div style={{cursor: 'pointer',  alignItems: 'center', margin: '0px', marginTop: '-4px'}}><Icons.CloseBold /></div>
                    </div>
                    </div>
                    {
                        !(modalUsers && usersSelected) ? 
                        <div className='modal_list'>
                        {
                            eventsSelected.map((item: any, index: number) => {
                                let color = typesList.filter((type: any) => type.activity_tag.toLocaleLowerCase().split(' ').join("") === item.name?.toLocaleLowerCase().split('_').join(""))[0]?.color;
                                return (
                                    <div key={index} className='shift_box' style={{display: 'flex', justifyContent: 'space-between', marginTop: '5px'}}>
                                        <div className='me-2 d-flex align-items-center'>
                                        <div className="shift_circle me-2" style={{ backgroundColor: item.name === 'absent' ? item?.color : (item.name === 'active' ? item?.color : color)}}></div>
                                        {`${item.count} ${formatName(item.name)}`}
                                        </div>
                                        <div className='viewallusers'
                                            style={{
                                                pointerEvents: item.count <= 0 ? 'none' : 'auto',
                                                opacity: item.count <= 0 ? 0.3 : 1
                                            }}
                                            onClick={() => _handleOpenUsers(item)}
                                        >
                                            users list
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    :
                    <div className='users_modal_list'>
                    {
                        usersSelected?.map((item: any, index: number) => {
                            return (
                                <div key={index} style={{display: 'flex', justifyContent: 'space-between', marginTop: '5px'}}>
                                    <div>
                                    {`${item}`}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                    }
                </div>
            </div>
        }
    </div>
}

export default ShiftListPage;